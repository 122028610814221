import React, {Component} from 'react';
import Layout from "../../components/layout";
import {deployments as deploymentsApi} from "../../services/api";
import Modal from "../../components/modal";

class EnvHistoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.viewOutput = this.viewOutput.bind(this);
    }

    componentDidMount() {
        let {id} = this.props.match.params;
        deploymentsApi.list(id).then(deployments => {
            console.log(deployments);
            this.setState({deployments});
        })
    }

    viewOutput(id) {
        return () => {
            deploymentsApi.getOutput(id).then(output => {
                this.setState({output});
            });
        };
    }

    render() {
        let {id} = this.props.match.params;
        let {deployments, output} = this.state;

        return <Layout location={this.props.location} secure>
            <h1 className="mb-5">Deployment History</h1>

            <span className="inline-block mb-5 px-3 py-2 rounded-full bg-purple text-white">{id}</span>

            {deployments && <div>
                {deployments.length ?
                    <div>
                        <table>
                            <thead>
                            <tr>
                                <th>Target</th>
                                <th>Started By</th>
                                <th>Time Started</th>
                                <th>Time Finished</th>
                                <th>Status</th>
                                <th />
                            </tr>
                            </thead>

                            <tbody>
                            {deployments.map(d => <tr key={d.Id}>
                                <td>{d.AppName || <em className="text-grey-dark">Environment</em>}</td>
                                <td>{d.AccountName}</td>
                                <td>{d.TimeStarted}</td>
                                <td>{d.TimeFinished}</td>
                                <td>
                                    {d.Status < 0 && !d.TimeFinished && <span className="text-blue">Running</span>}
                                    {d.Status < 0 && !!d.TimeFinished && <span className="text-orange">Unknown</span>}
                                    {d.Status === 0 && <span className="text-green">Successful</span>}
                                    {d.Status > 0 && <span className="text-red">Error</span>}
                                </td>
                                <td>{d.HasOutput && <button className="button blue" onClick={this.viewOutput(d.Id)}>View Output</button>}</td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div> :
                    <em>No Deployment History</em>}

            </div>}

            {output && <Modal title="View Output" onClose={() => this.setState({output: null})} className="wide">
                <pre className="bg-grey-darkest rounded px-5 py-5 text-grey-lighter mb-5 overflow-x-auto">{output}</pre>
                <button className="button" onClose={() => this.setState({output: null})}>Close</button>
            </Modal>}

        </Layout>;
    }
}

export default EnvHistoryPage;