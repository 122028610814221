import React, {Component} from 'react';
import Layout from "../../components/layout";
import {environments as environmentsApi, deployments as deplymentsApi} from "../../services/api";

const pollInterval = 750;

class NewDeployPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            app: '',
            started: false,
            output: '',
            forceDeploy: false,
        };

        this.updateApp = this.updateApp.bind(this);
        this.setForce = this.setForce.bind(this);
        this.start = this.start.bind(this);
        this.poll = this.poll.bind(this);
    }

    componentDidMount() {
        let {id} = this.props.match.params;
        environmentsApi.get(id).then(environment => {
            let apps = new Set();
            environment.nodes.forEach(node => {
                node.apps.forEach(app => {
                    if (!app.placeholder)
                        apps.add(app.name);
                });
            });
            apps = Array.from(apps).sort();
            this.setState({apps});
        })
    }

    updateApp(e) {
        this.setState({app: e.target.value});
    }

    setForce(e) {
        this.setState({forceDeploy: e.target.checked});
    }

    start() {
        let {id} = this.props.match.params;

        this.setState({started: true});
        let deployment = {
            environment: id,
            appName: this.state.app,
        };
        deplymentsApi.create(deployment)
            .then(({id}) => {
                this.deploymentId = id;
                return deplymentsApi.start(id, this.state.forceDeploy);
            })
            .then(() => {
                this.polling = true;
                this.poll();
            });
    }

    poll() {
        deplymentsApi.get(this.deploymentId)
            .then(deployment => {
                if (deployment.Status >= 0)
                    this.polling = false;
                this.setState({deployment});
                return deplymentsApi.getOutput(this.deploymentId);
            })
            .then(output => {
                this.setState({output});
                if (this.polling)
                    window.setTimeout(this.poll, pollInterval);
            })
    }

    render() {
        let {id} = this.props.match.params;
        let {app, apps, started, output, deployment, forceDeploy} = this.state;

        let prodMagnatron = app === 'magnatron' && window.location.host === 'magnatron.torchinsight.com';
        let notAllowed = !app || prodMagnatron;

        return <Layout location={this.props.location} secure>
            <h1 className="mb-5">Create New Deployment</h1>
            <span className="inline-block mb-5 px-3 py-2 rounded-full bg-purple text-white">{id}</span>
            <div className="w-1/2">
                <div className="font-bold mb-3">App:</div>
                {apps && <div className="select">
                    <select value={app} onChange={this.updateApp}>
                        <option value="">(Select one)</option>
                        {apps.map(a => <option value={a} key={a}>{a}</option>)}
                    </select>
                </div>}
                <div className="my-3">
                    <label>
                        <input type="checkbox" checked={forceDeploy} onChange={this.setForce} />
                        &nbsp;
                        Force Deploy
                    </label>
                </div>
                <button className="button mt-3" disabled={started || notAllowed} onClick={this.start}>Start Deploy</button>
                {prodMagnatron && <div className="mt-4 text-red">Production Magnatron cannot deploy itself. You will need to run the deploy locally.</div>}
            </div>

            {started && <div>
                <hr className="border-b my-10" />

                {deployment && <div className="mb-5">
                    <span className="mr-4">Status:</span>
                    {deployment.Status < 0 && <span className="text-blue font-bold">Running <i className="fa fa-spin fa-spinner" /></span>}
                    {deployment.Status === 0 && <span className="text-green font-bold">Successful</span>}
                    {deployment.Status > 0 && <span className="text-red font-bold">Failure</span>}
                </div>}
                <pre className="bg-grey-darkest rounded px-5 py-5 text-grey-lighter overflow-x-auto">{output}</pre>
            </div>}
        </Layout>;
    }
}

export default NewDeployPage;