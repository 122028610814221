import React, {Component} from 'react';
import Layout from "../../components/layout";
import {actions as actionsApi} from "../../services/api";


class ActionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            action: {Params: []},
            params: {},
        };

        this.runAction = this.runAction.bind(this);
    }

    componentDidMount() {
        let {id} = this.props.match.params;
        actionsApi.get(id).then(action => {
            action.Params = action.Params ? JSON.parse(action.Params) : [];
            this.setState({action});
        });
    }

    updateParam(name) {
        return e => {
            let {params} = this.state;
            params[name] = e.target.value;
            this.setState({params});
        }
    }

    runAction() {
        let {id} = this.props.match.params;
        let {params} = this.state;
        actionsApi.run(id, params)
            .then(() => {
                this.setState({started: true});
            })
            .catch(err => {
                console.error(err);
                this.setState({err: err.message || err});
            })
    }

    render() {
        let {started, err, params} = this.state;

        let {action} = this.state;
        let complete = action.Params.every(p => params[p.name]);

        return <Layout location={this.props.location} secure>
            <h1 className="mb-4">{action.Name}</h1>
            <p className="mb-8">{action.Description}</p>

            {action.Params.map((p, i) => <div key={i} className="md:flex md:items-center mb-6">
                <div>
                    <label className="block text-grey font-bold mb-1 md:mb-0 pr-4">
                        {p.label}
                    </label>
                </div>
                <div className="md:w-1/3">
                    {p.values ?
                        <div className="select">
                            <select value={params[p.name] || ''} onChange={this.updateParam(p.name)}>
                                <option value="" />
                                {p.values.map((v, i) => <option value={v} key={i}>{v}</option>)}
                            </select>
                        </div>:
                        <input type="text" value={params[p.name] || ''} onChange={this.updateParam(p.name)}/>}
                </div>
            </div>)}

            {!started && !err && <button className="button" onClick={this.runAction} disabled={!complete}><i className="fa fa-play" /> Run Action</button>}
            {started && <span className="text-green">Action has been initiated</span>}
            {err && <span className="text-red">{err}</span>}

        </Layout>
    }
}

export default ActionPage;