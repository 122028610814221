import React, {Component} from 'react';
import Layout from "../components/layout";
import {auth} from "../services/api";

class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };

        this.login = this.login.bind(this);
    }

    handleUpdate(name) {
        return e => {
            this.setState({[name]: e.target.value});
        }
    }

    login(e) {
        e.preventDefault();

        let {email, password} = this.state;
        if (!email || !password)
            return;

        this.setState({err: null});
        auth.login(email, password)
            .then(() => {
                window.location = '/home';
            })
            .catch(err => {
                console.error(err);
                this.setState({err: err.message || err});
            })
    }

    render() {
        let {email, password, err} = this.state;

        return <Layout location={this.props.location}>

            <div className="flex">
                <div className="flex-grow" />
                <form className="w-1/2" onSubmit={this.login}>
                    <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/3" />
                        <h1>Login</h1>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/3">
                            <label className="block text-grey font-bold md:text-right mb-1 md:mb-0 pr-4"
                                   htmlFor="inline-email">
                                Email
                            </label>
                        </div>
                        <div className="md:w-2/3">
                            <input id="inline-email" type="text" value={email} onChange={this.handleUpdate('email')} />
                        </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/3">
                            <label className="block text-grey font-bold md:text-right mb-1 md:mb-0 pr-4"
                                   htmlFor="inline-username">
                                Password
                            </label>
                        </div>
                        <div className="md:w-2/3">
                            <input id="inline-username" type="password" value={password} onChange={this.handleUpdate('password')} />
                        </div>
                    </div>
                    <div className="md:flex md:items-center">
                        <div className="md:w-1/3" />
                        <div className="md:w-2/3">
                            <button className="button">
                                Log In
                            </button>

                            {err && <div className="text-red">{err}</div>}
                        </div>
                    </div>
                </form>
                <div className="flex-grow" />
            </div>
        </Layout>
    }
}

export default LoginPage;