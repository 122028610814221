import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from "../../components/layout";
import {actions as actionsApi} from "../../services/api";

class UserHomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        actionsApi.list().then(actions => {
            this.setState({actions});
        });
    }


    render() {
        let {actions} = this.state;
        return <Layout location={this.props.location} secure>
            <h1 className="mb-10">Home</h1>

            <div className="bg-grey-lighter rounded px-5 py-6 my-4">
                <h2 className="mb-4 font-thin">Actions</h2>

                <div className="flex">
                    {actions && actions.map(action => <Link className="button mr-4" to={`/action/${action.ShortName}`} key={action.Id}>
                        {action.Name}
                    </Link>)}
                </div>
            </div>

            <div className="bg-grey-lighter rounded px-5 py-6 my-4">
                <h2 className="mb-4 font-thin">Environments</h2>

                <div className="flex">
                    <Link className="button mr-4" to="/environments">View Environments</Link>
                    <Link className="button mr-4" to="/environments/prod/deploy">Create Deployment</Link>
                    <Link className="button mr-4" to="/environments/prod/history">Deployment History</Link>
                </div>
            </div>

            <div className="bg-grey-lighter rounded px-5 py-6 my-4">
                <h2 className="mb-4 font-thin">Resources</h2>

                <div className="flex">
                    <a target="_blank" className="button mr-4" href="https://monitoring.torchinsight.com">Monitoring</a>
                    <a target="_blank" className="button mr-4" href="https://in.torchinsight.com">Insighter</a>
                    <a target="_blank" className="button mr-4" href="https://ringmaster.torchinsight.com">Ringmaster</a>
                </div>
            </div>

        </Layout>
    }
}

export default UserHomePage;