import React, {Component} from "react";
import PropTypes from "prop-types";

export default class Modal extends Component {
    render() {
        let className = (this.props.className || '');

        return <div className="fixed pin">
            <div className="absolute bg-grey-darkest pin opacity-50" />
            <div className={`absolute bg-white rounded px-10 py-10 centered ${className}`}>
                <div className="text-lg mb-5 text-grey-darker">
                    <button onClick={this.props.onClose} className="bg-white hover:bg-grey-lighter text-grey-darker hover:text-grey-darker font-bold rounded-full float-right" style={{marginTop: -7}}>
                        <i className="fa fa-times" />
                    </button>
                    <strong>{this.props.title}</strong>
                </div>
                {this.props.children}
            </div>
        </div>;
    }
}

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};