import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from "../../components/layout";
import {environments as environmentsApi} from "../../services/api";

class EnvironmentsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        environmentsApi.list().then(environments => {
            this.setState({environments});
        });
    }


    render() {
        let {environments} = this.state;

        return <Layout location={this.props.location} secure>
            <h1 className="mb-10">Environments</h1>

            {environments && <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Id</th>
                    <th>Domain</th>
                    <th />
                </tr>
                </thead>

                <tbody>
                {Object.entries(environments).map(([id, env]) => <tr key={id}>
                    <td>{env.name}</td>
                    <td>{id}</td>
                    <td>{env.domain && <a href={'http://' + env.domain} target="_blank">{env.domain}</a>}</td>
                    <td>
                        <Link to={`/environments/${id}`} className="button">Details</Link>
                        <Link to={`/environments/${id}/deploy`} className="button ml-2">Deploy</Link>
                        <Link to={`/environments/${id}/history`} className="button ml-2">History</Link>
                    </td>
                </tr>)}
                </tbody>
            </table>}

        </Layout>
    }
}

export default EnvironmentsPage;