import React, {Component} from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import Navbar from "./navbar";
import auth from "../services/auth";

export default class Layout extends Component {
    render() {
        let {secure, location, admin} = this.props;
        if ((secure || admin) && !auth.principal)
            return <Redirect to={{
                pathname: '/login',
                state: {
                    path: location.pathname
                }
            }} />;

        if (admin && !auth.principal.admin)
            return <Redirect to="/" />;

        return <div>
            <Navbar/>
            <div className="container mx-auto">
                {this.props.children}
            </div>
        </div>
    }
}

Layout.propTypes = {
    secure: PropTypes.bool,
    admin: PropTypes.bool,
    location: PropTypes.object.isRequired,
};