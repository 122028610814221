import React, {Component} from 'react';
import {Link} from "react-router-dom";
import auth from "../../services/auth";
import logoLg from "./images/logo-lg.png";

class HomePage extends Component {

    render() {
        return <div className="w-screen h-screen relative text-center" id="home">

            <Link to={auth.principal ? '/home' : '/login'} className="login-button relative inline-block text-white pt-3 cursor-pointer no-underline" id="login-button" onClick={this.loginClick}>
                <svg className="absolute pin-t pin-l" width="166px" height="40px" viewBox="0 0 166 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <path d="M0.742228778,0.5 L15.3368234,36.5 L150.663177,36.5 L165.257771,0.5 L0.742228778,0.5 Z" stroke="#FFFFFF" />
                    </g>
                </svg>
                <span>{auth.principal ? 'Home' : 'Login'}</span>
            </Link>

            <a href="https://torchinsight.com" className="login-button relative inline-block text-white pt-3 cursor-pointer no-underline" id="torch-button">
                <svg className="absolute pin-t pin-l" width="166px" height="40px" viewBox="0 0 166 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <path d="M0.742228778,0.5 L15.3368234,36.5 L150.663177,36.5 L165.257771,0.5 L0.742228778,0.5 Z" stroke="#FFFFFF" />
                    </g>
                </svg>
                <span>Torch</span>
            </a>

            <div className="absolute w-full" id="home-logo">
                <img src={logoLg} />
            </div>
        </div>
    }
}

export default HomePage;