import React, {Component} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import UserHomePage from "./pages/user-home";
import ActionPage from "./pages/actions";
import EnvironmentsPage from "./pages/environments";
import EnvironmentViewPage from "./pages/environments/view";
import AppPage from "./pages/app";
import EnvHistoryPage from "./pages/environments/history";
import NewDeployPage from "./pages/environments/new-deploy";

class The404 extends Component {
    render() {
        return (
            <div>
                404 - No can do
            </div>
        );
    }
}

let appElement = document.getElementById('app');

ReactDOM.render(<BrowserRouter>
    <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/home" component={UserHomePage} />
        <Route exact path="/action/:id" component={ActionPage} />

        <Route exact path="/environments" component={EnvironmentsPage}/>
        <Route exact path="/environments/:id" component={EnvironmentViewPage}/>
        <Route exact path="/environments/:id/history" component={EnvHistoryPage}/>
        <Route exact path="/environments/:id/deploy" component={NewDeployPage}/>

        <Route exact path="/apps/:app" component={AppPage}/>

        <Route component={The404} />
    </Switch>
</BrowserRouter>, appElement);