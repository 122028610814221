import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from "../../components/layout";
import {environments as environmentsApi} from "../../services/api";

class EnvironmentViewPage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let {id} = this.props.match.params;
        environmentsApi.get(id).then(environment => {
            this.setState({environment});
        })
    }


    render() {
        let {environment} = this.state;
        let {id} = this.props.match.params;

        return <Layout location={this.props.location} secure>
            {environment ?
                <div>
                    <h1 className="mb-10">{environment.name}</h1>

                    <div>
                        <Link to={`/environments/${id}/deploy`} className="button">Deploy</Link>
                        <Link to={`/environments/${id}/history`} className="button ml-3">History</Link>
                    </div>

                    {environment.nodes.map(node => <div key={node.name} className="my-5 px-4 py-4 rounded bg-grey-lighter">
                        <h2 className="mb-4">{node.name}</h2>

                        {node.apps.map((app, i) => <div key={i} className="w-1/4 px-2 py-2 my-2 inline-block">
                            <div className="bg-white border h-full px-5 py-4 rounded shadow-lg w-full">
                                <div className="font-bold mb-1">{app.name}</div>

                                {app.branch && <div className="flex mb-1">
                                    <div className="w-1/2">
                                        Branch:
                                    </div>
                                    <div className="w-1/2">
                                        <code className="bg-blue-lighter px-2 py-1 rounded text-grey-darker text-sm">
                                            {app.branch}
                                        </code>
                                    </div>
                                </div>}

                                {app.placeholder && <div className="flex mb-1">
                                    <div className="w-1/2">
                                        Placeholder:
                                    </div>
                                    <div className="w-1/2">
                                        ✅
                                    </div>
                                </div>}

                                <div className="flex mb-1">
                                    <div className="w-1/2">
                                        Port:
                                    </div>
                                    <div className="w-1/2">
                                        <code className="bg-blue-lighter px-2 py-1 rounded text-grey-darker text-sm">
                                            {app.port}
                                        </code>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <Link to={`/apps/${app.name}?env=${id}`} className="button light small"><i className="fa fa-info-circle" /></Link>
                                    <button className="button light small ml-2"><i className="fa fa-trash" /></button>
                                    <button className="button light small ml-2"><i className="fa fa-copy" /></button>
                                </div>
                            </div>
                        </div>)}
                    </div>)}
                </div> :
                <i className="fa fa-spinner fa-spin fa-xl" />}
        </Layout>;
    }
}

export default EnvironmentViewPage;