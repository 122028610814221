

// let token = global.sessionStorage && sessionStorage.getItem('jwt');
let token = global.sessionStorage && sessionStorage.getItem('jwt');
if (!token) {
    token = global.localStorage && localStorage.getItem('jwt');
    let exp = global.localStorage && localStorage.getItem('exp');
    if (!exp || Number(exp) < new Date().getTime()) {
        localStorage.clear();
        token = null;
    }
}

export function getPrincipal(token) {
    if (!token)
        return null;
    return JSON.parse(atob(token.split('.')[1]));
}


const auth = {
    principal: getPrincipal(token),
    token,
};
export default auth