import React, {Component} from "react";
import {Link} from "react-router-dom";
import logoImg from "../images/logo-sm.png";
import auth from "../services/auth";


export default class Navbar extends Component {

    constructor(props) {
        super(props);
        Navbar.logOut = Navbar.logOut.bind(this);
    }

    static logOut() {
        sessionStorage.removeItem('jwt');
        localStorage.removeItem('jwt');
        window.location = '/';
    }

    render() {
        return <div className="bg-grey-lighter mb-5 py-4">
            <div className="container mx-auto flex items-center">
                <Link to="/home"><img src={logoImg} alt="Ringmaster" className="h-4" /></Link>
                <div className="flex-grow" />
                {/*{auth.principal && auth.principal.admin && <Link to="/admin" className="mr-10">Admin</Link>}*/}
                {auth.principal && <a className="cursor-pointer" onClick={Navbar.logOut}>Log Out</a>}
            </div>
        </div>;
    }
}
