import React, {Component} from 'react';
import Layout from "../../components/layout";
import {apps as appsApi} from "../../services/api";

class AppPage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let {app} = this.props.match.params;
        appsApi.get(app).then(app => {
            console.log(app);
            this.setState({app});
        });
        appsApi.getDeployment(app, 'hard').then(hardDeploy => {
            console.log(hardDeploy);
            this.setState({hardDeploy});
        });
    }


    render() {
        let {app: appName} = this.props.match.params;
        let {app, hardDeploy} = this.state;

        let params = new URLSearchParams(window.location.search);
        let env = params.get('env') || 'prod';

        return <Layout location={this.props.location} secure>
            <h1 className="mb-10">{appName}</h1>
            {app ?
                <div>
                    <div>
                        App Type:
                        <code className="bg-blue-lighter px-2 py-1 rounded text-grey-darker text-sm ml-2">
                            {app.type}
                        </code>
                    </div>

                    <div className="mt-3">
                        GitLab ID:
                        <code className="bg-blue-lighter px-2 py-1 rounded text-grey-darker text-sm ml-2">
                            {app.gitlab_app_id}
                        </code>
                    </div>

                    <h2 className="my-5">Deployment Process</h2>
                    {hardDeploy ?
                        <div>
                            {hardDeploy.map((step, i) => <div key={i} className="bg-grey-lighter w-1/2 px-5 py-5 mb-3 rounded shadow flex items-center">
                                <span className="bg-purple h-8 inline-block inline-flex items-center justify-center mr-5 rounded-full shadow text-lg text-white w-8">
                                    {i + 1}
                                </span>
                                <div className="inline-flex flex-col">
                                    <div>{step.name}</div>
                                    {step.details && <div className="text-grey-dark mt-2">
                                        {step.details.replace('{{ env_name }}', env)}
                                    </div>}
                                </div>
                            </div>)}
                        </div> :
                        <div>
                            <i className="fa fa-spinner fa-spin fa-xl" /> Loading
                        </div>}
                </div> :
                <div>
                    <i className="fa fa-spinner fa-spin fa-xl" /> Loading
                </div>}
        </Layout>;
    }
}

export default AppPage;