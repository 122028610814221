import axios from "axios";
import authLib, {getPrincipal} from "./auth";

const authHeader = (headers) => ({headers: Object.assign({Authorization: 'Bearer ' + authLib.token}, headers)});

let client = axios.create();
client.interceptors.response.use(res => {
    return res.data;
}, err => {
    // if (err.response.status === 401 && window.location.pathname !== '/login') {
    //     window.location = '/login';
    //     return;
    // }
    let message = err.message || (err.response.data.errorMessage ? err.response.data.errorMessage.replace(/^\[\w+]\s/, '') : '');
    return Promise.reject(message);
});

function buildQuery(args) {
    let parts = [];
    Object.keys(args).forEach(key => {
        parts.push(key + '=' + encodeURIComponent(args[key]));
    });
    if (!parts.length)
        return '';
    return '?' + parts.join('&');
}

const auth = {
    login: (email, password) => client.post('/api/auth/login', {email, password})
        .then(token => {
            sessionStorage.setItem('jwt', token);
            localStorage.setItem('jwt', token);
            localStorage.setItem('exp', new Date().getTime() + 24 * 60 * 60 * 1000); // In one day
            authLib.token = token;
            authLib.principal = getPrincipal(token);
            return authLib.principal;
        }),
};

const actions = {
    list: () => client.get('/api/actions/', authHeader()),
    get: id => client.get(`/api/actions/${id}`, authHeader()),
    run: (id, params={}) => client.post(`/api/actions/${id}/run`, params, authHeader()),
};

const environments = {
    list: () => client.get('/api/environments/', authHeader()),
    get: envId => client.get(`/api/environments/${envId}`, authHeader()),
};

const apps = {
    list: () => client.get('/api/apps/', authHeader()),
    get: name => client.get(`/api/apps/${name}`, authHeader()),
    getDeployment: (name, deployType) => client.get(`/api/apps/${name}/deploy/${deployType}`, authHeader()),
};

const deployments = {
    list: envName => client.get('/api/deployments/' + envName, authHeader()),
    create: deployment => client.post('/api/deployments/', deployment, authHeader()),
    get: id => client.get(`/api/deployments/${id}`, authHeader()),
    start: (id, forceDeploy=false) => client.post(`/api/deployments/${id}/start` + buildQuery({forceDeploy}), null, authHeader()),
    getOutput: id => client.get(`/api/deployments/${id}/output`, authHeader()),
};

export {auth, actions, environments, apps, deployments}